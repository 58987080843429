

/*
Page Flip Table of contents

Markup:
<ul class="flipbook-nav">
	<li>Hello</li>
	<li>Hello</li>
	<li>
		<ul>
			<li>Hello</li>
		</ul>
	</li>
</ul>

Styleguide 1.0.0

*/


.toc{

	position:relative;

	&__header{
		width:100%;

		h1{
			font-size:48px !important;
		}
	}
	&__columns{
		position:absolute;

		a {
			display: block;
		}
		h2{
			font-size:32px;
			margin-bottom: 20px;
			a{
				color:#ccc;
				&:hover {
					color: #F48D35;
				}
			}

		}

		ul.toc__columns__list{
			margin:-10px 0 30px 0;
			padding:0;

			li{
				list-style: none;
				padding:0;
				margin-bottom: 15px;
				//override criteos default list bullets
				&:before {
					content: '' !important;
					width: 0 !important;
					margin-left: 0 !important;
					display: inline;
				}
				a{
					//color:#fff;
					font-size: 22px;
				}
			}
		}
	}

	&__icon {
		display: inline-block;
		vertical-align: middle;
		background-color: white;
		border-radius: 50%;
		padding: 4px;
		margin-right: 10px;
		transform: scale(.82);
	}


	&--intro{
		width:380px;
		top: 135px;
		left: 68px;
	}
}