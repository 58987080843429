

/*
Subscribe Row

Markup:
<div class="row hc-subscribe">
	<div class="container">
		<div class="col-md-7">
			<h4>Subscribe to the Newsletter for more Holiday E-Commerce Tips.</h4>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
		</div>
		<div class="col-md-5">
			<form class="form validated-form clearfix" action="" method="post">
				<div class="form-group clearfix">
					<label class="col-md-4" for="emailAddress">
						Email Address
					</label>
					<div class="col-md-8">
						<input type="text" id="emailAddress" name="emailAddress" class="form-control" data-validate="required email" autocomplete="email">
					</div>
				</div>
				<p>
					<input type="hidden" name="redirect_url" value="http://www.criteo.com/resources/mobile-commerce-report/thank-you/">
					<input type="hidden" name="event-name" value="mobile commerce report">
					<input type="hidden" name="umbraco_node" value="36609">
					<input type="submit" value="Sign up" class="cta">
				</p>
			</form>
		</div>
	</div>
</div>


Styleguide 1.5.

*/

.hc-subscribe {
	padding: 40px 0 30px;
	h4 {
		font-size: 24px;
		line-height: 32px;
		color: $brandcolor;
		margin-bottom: 10px;
	}
	label {
		line-height: 40px;
	}
	&__error-msg {
		color: red;
		&--in {
			padding: 5px 10px;
		}
	}
	h5 {
		font-size: 22px;
		line-height: 26px;
		margin: 0;
	}
	@media (min-width: $screen-sm-min) {
		padding: 60px 0 40px;
		h4 {
			font-size: 30px;
			line-height: 36px;
			color: $brandcolor;
		}
	}
	@media (min-width: $screen-md-min) {
		&__form {
			padding-top: 15px;
		}
	}
}


