

/*
Release Row

Markup:
<div class="row hc-release hc-release--august">
	<div class="container">
		<div class="col-md-6 hc-release__header">
			<span class="hc-release__month">August</span>
			<h1>Tips from the Campaign Readiness Guide</h1>
			<p>Lorem ipsum dolor sit amet. Sed ut perspiciatis unde omnis iste natus error sit voluptatem. </p>
			<a href="" class="hc-cta hc-cta--white"><span class="hc-cta__label">Read the White Paper</span> <span class="glyphicon glyphicon-chevron-right"></span></a>
		</div>
		<!-- Add the extra clearfix for only the required viewport -->
		<div class="clearfix visible-md-block"></div>
		<div class="col-md-6 hc-release__driver hc-release__driver--left">
			<div class="row">
				<div class="col-xs-8 col-md-8 hc-release__driver-container">
					<h2>How technology shaped the industry</h2>
					<p>Lorem ipsum dolor sit amet. Sed ut perspiciatis unde omnis iste natus error sit voluptatem. </p>
				</div>
				<a href="" class="hc-cta"><span class="hc-cta__label">Read the White Paper</span> <span class="glyphicon glyphicon-chevron-right"></span></a>
			</div>
		</div>
		<div class="col-md-6 hc-release__driver hc-release__driver--right">
			<div class="row">
				<div class="col-xs-8 col-md-6 hc-release__driver-container">
					<h2>Why you need cross-device capabilities this holiday season</h2>
				</div>
				<a href="" class="hc-cta"><span class="hc-cta__label">Watch the Video</span> <span class="glyphicon glyphicon-chevron-right"></span></a>
			</div>
		</div>
	</div>
</div>

Styleguide 1.4.

*/

@mixin release($color) {
	.hc-release__header {
		h1 {
			color: $color;
		}
		.hc-cta {
			background-color: $color;
		}
	}
	.hc-release__driver {
		.hc-cta {
			background-color: $color;
		}
	}
}

.hc-release {
	/*
	background-color: #444;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	*/
	//color: white;
	padding: 40px 0 30px;
	background: #e9e8e6;
	&__month {
		border-bottom: 1px solid white;
		display: inline-block;
		font-size: 20px;
		line-height: 26px;
		margin-bottom: 30px;
	}
	&__header {
		margin-bottom: 20px;
		> .row {
			background-color: white;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: cover;
			padding: 20px 0;
		}
		h1 {
			font-size: 32px;
			line-height: 38px;
			margin-bottom: 20px;
		}
		p {
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 20px;
		}
		.hc-cta {
			margin-top: 10px;
			margin-left: 20px;
		}
	}
	&__teaser {
		color: #d6d6d6;
	}
	/*
	&__callout {
		text-align: center;
	}
	&__callout-circle {
		text-align: center;
		border-radius: 50%;
		padding: 60px 30px 0;
		width: 300px;
		height: 300px;
		display: inline-block;
		margin-bottom: 30px;
		p {
			font-size: 22px;
			line-height: 28px;
			margin-bottom: 0;
		}
		img {
			display: none;
			margin-bottom: 20px;
		}
	}
	*/
	&__driver {
		color: $branddarkgrey;
		//padding: 0;
		margin-bottom: 20px;
		> .row {
			background-color: white;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: cover;
			padding: 20px 0;
		}
		h2 {
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 15px;
		}
		p {
			color: $brandlightgrey;
			font-size: 16px;
			line-height: 20px;
		}
		.hc-cta {
			margin-left: 20px;
		}
		&-image {
			margin-bottom: 20px;
		}
	}
	@media (min-width: $screen-sm-min) {
		padding: 60px 0 50px;
		&__month {
			font-size: 30px;
			line-height: 37px;
			margin-bottom: 50px;
		}
		&__header {
			h1 {
				font-size: 44px;
				line-height: 50px;
				margin-bottom: 15px;
			}
			p {
				font-size: 18px;
				line-height: 22px;
			}
			/*
			.hc-cta {
				margin-top: 30px;
				margin-bottom: 130px;
			}*/
		}
		/*
		&__callout-circle {
			width: 400px;
			height: 400px;
			p {
				font-size: 26px;
				line-height: 32px;
				margin-bottom: 20px;
			}
			img {
				display: inline-block;
			}
		}
		*/
		&__driver {
			h2 {
				font-size: 22px;
				line-height: 28px;
			}
		}
	}
	@media (min-width: $screen-md-min) {
		/*
		&__callout {
			text-align: right;
		}
		*/
		&__header {
			> .row {
				min-height: 360px;
			}
			h1 {
				font-size: 42px;
				line-height: 50px;
			}
			&-cta {
				position: absolute;
				left: 20px;
				bottom: 20px;
			}
			.hc-cta {
				margin-left: 0;
			}
		}
		&__driver {
			> .row {
				min-height: 220px;
			}
			&-container {
				padding-bottom: 30px;
			}
			/*
			&-image {
				padding-top: 60px;
			}
			*/
			.hc-cta {
				position: absolute;
				left: 20px;
				bottom: 20px;
				margin-left: 0;
			}
			&--left {
				padding-right: 10px;
			}
			&--right {
				padding-left: 10px;
			}
		}
	}
	&--august {
		@include release($color-red);
		.hc-release__month {
			display: none;
		}
	}
}
