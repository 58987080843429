#pageflip {
	width: 100%;
	overflow: hidden;
	/*visibility: hidden;*/
	display: block;
	/* use display:none only if needed! */
}

.pageflip-container {
	padding-top: 0px;
}
.book-container {
}
.book-content {
	opacity: 0;
	-webkit-transition: opacity 1s;
	-moz-transition: opacity 1s;
	-o-transition: opacity 1s;
	-ms-transition: opacity 1s;
}
.book-offset {
	-webkit-perspective: 3000px;
	-moz-perspective: 3000px;
	-ms-perspective: 3000px;
	perspective: 3000px;
	-webkit-perspective-origin-x: 50%;
	-webkit-perspective-origin-y: 50%;
	-moz-perspective-origin-x: 50%;
	-moz-perspective-origin-y: 50%;
	-ms-perspective-origin-x: 50%;
	-ms-perspective-origin-y: 50%;
	perspective-origin-x: 50%;
	perspective-origin-y: 50%;
}


.page-container {
	position: absolute;
	overflow: hidden;
}

#dropshadow {
	-webkit-box-shadow: 0 10px 30px rgba(0,0,0,1);
	-moz-box-shadow: 0 10px 30px rgba(0,0,0,1);
	-o-box-shadow: 0 10px 30px rgba(0,0,0,1);
	-ms-box-shadow: 0 10px 30px rgba(0,0,0,1);
	box-shadow: 0 10px 30px rgba(0,0,0,1);
}

.flip-shadowA {
	background-image: -o-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,1));
	background-image: -ms-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,1));
	background-image: -moz-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,1));
	background-image: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,1));
}
.flip-shadowB {
	background-image: -o-linear-gradient(left, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,0));
	background-image: -ms-linear-gradient(left, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,0));
	background-image: -moz-linear-gradient(left, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,0));
	background-image: -webkit-linear-gradient(left, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,0));
}
.flip-topshadow {
	background-image: -o-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,.5), rgba(0,0,0,1), rgba(0,0,0,0));
	background-image: -ms-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,.5), rgba(0,0,0,1), rgba(0,0,0,0));
	background-image: -moz-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,.5), rgba(0,0,0,1), rgba(0,0,0,0));
	background-image: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,.5), rgba(0,0,0,1), rgba(0,0,0,0));
}
.emboss-left {
	background-image: -o-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,1));
	background-image: -ms-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,1));
	background-image: -moz-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,1));
	background-image: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,1));
	pointer-events: none;
}
.emboss-right {
	background-image: -o-linear-gradient(left, rgba(0,0,0,.5), rgba(0,0,0,0));
	background-image: -ms-linear-gradient(left, rgba(0,0,0,.5), rgba(0,0,0,0));
	background-image: -moz-linear-gradient(left, rgba(0,0,0,.5), rgba(0,0,0,0));
	background-image: -webkit-linear-gradient(left, rgba(0,0,0,.5), rgba(0,0,0,0));
	pointer-events: none;
}


/* Controls layout/animation/hover fx ----------------------------------- */

#pageflip-controls {
	position: absolute;
	bottom: 0px;
	padding: 8px 0 16px;
	width: 100%;
	overflow: hidden;
	text-align: center;
	opacity: 0;
	-webkit-transition: opacity 1s;
	-moz-transition: opacity 1s;
	-o-transition: opacity 1s;
	-ms-transition: opacity 1s;
	color: #888;
	font-size: 12px;
}
.centered-buttons,
.left-buttons,
.right-buttons {
	height: 26px;
	//overflow: hidden;
}

.centered-buttons {
	position: relative;
	top: 0px;
	left: auto;
	right: auto;
	margin: 0px auto;
	width: 254px;
}
.left-buttons {
	float: left;
	width: 104px;
	margin-left: 32px;
}
.right-buttons {
	float: right;
	//width: 104px;
	margin-right: 32px;
}

.control-bar-button {
}

.control-bar-button,
.control-bar-pager {
	float: left;
	height: 26px;
}
.right {
	//float: right;
}
.control-bar-pager {
	padding-top: 2px;
	width: 150px;
	text-align: center;
}

#pagerin {
	color: rgba(0,0,0,0.3);
	border: none;
	width: 140px;
	height: 22px;
    padding: 0px;
	font-size: 14px;
	/*font-family: Helvetica, Arial, sans-serif;*/
    border-style: none;
    border-color: Transparent;
    overflow: auto;
    background: none;
    text-align: center;
}

.control-bar-button g,
.control-bar-pager g {
	fill: rgba(0,0,0,0.3);
	-webkit-transition: fill 0.2s;
	-moz-transition: fill 0.2s;
	-o-transition: fill 0.2s;
	-ms-transition: fill 0.2s;
}
.disabled {
	opacity: 0.2;
}

.control-bar-button:not(.disabled):hover g {
	fill: #FA0;
}
/*.control-bar-button.disabled:hover g,
.control-bar-pager.disabled:hover g  {
	fill: #888;
}*/
#pageflip-controls {
	color: rgba(0,0,0,0.3);
}

/* Thumbnails layout/animation/hover fx ----------------------------------- */

#pageflip-thumbnails {
	position: absolute;
	top: 0px;
	width: 100%;
	overflow: hidden;

	-webkit-transition: top 0.5s, opacity 0.5s;
	-moz-transition: top 0.5s, opacity 0.5s;
	-o-transition: top 0.5s, opacity 0.5s;
	-ms-transition: top 0.5s, opacity 0.5s;

}
#pageflip-thumbnails.hidden {
	top: -100px;
	opacity: 0;
}
#thumbnail-container {
	width: 10px;
}
.thumbnail-spread {
}
.thumbnail-page {
}
.thumbnail-spread,
.thumbnail-page {
	float: left;
	margin: 0 16px 16px 0px;
	/*margin: 0 8px 16px 8px;*/
	background: #888;
	-moz-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
	-webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
	box-shadow: 0 5px 10px rgba(0,0,0,0.2);
	overflow: hidden;
}
.thumbnail-button {
	float: left;
	margin: 0px;
	padding: 0px;
}
.thumbnail-button:hover {


}

#copyright-text {
	display: none;
	clear: both;
	padding-top: 10px;
}
.pageflip-credits {
	display: none;
}

/* Default Page setup */

.left-side {
	background: #666;
}

.right-side {
	background: #666;
}
.outer {
	background: none;
}

/*

	Responsive design

*/

@media screen and (max-width: 640px) {
	.control-bar-pager {
		display: none;
	}
	.centered-buttons {
		width: 160px;
		.control-bar-button {
			width: 40px;
		}
	}
}
@media screen and (max-width: 600px) {
	.left-buttons {
		display: none;
	}
	.centered-buttons {
		//width: 160px;
		float: left;
		margin-left: 48px;
	}
}
@media screen and (max-width: 480px) {
	.left-buttons {
		display: none;
	}
	.control-bar-button {
		width: 40px;
	}
	.right-buttons {
		display: block;
		width: 160px;
		margin-right: 48px;
	}
}
@media screen and (max-width: 440px) {
	.centered-buttons {
		//float: none;
		//left: auto;
		//right: auto;
		width: 120px;
		margin: 0 0 0 10px;
		.control-bar-button {
			width: 30px;
		}
	}
	.right-buttons {
		margin: 0 10px 0 0;
	}
	.left-buttons {
		display: none;
	}
}



