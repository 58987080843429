

/*
Page Flip Navigation

Markup:
<ul class="flipbook-nav">
	<li>Hello</li>
	<li>Hello</li>
	<li>
		<ul>
			<li>Hello</li>
		</ul>
	</li>
</ul>
Styleguide 1.0.0

*/

div.hc-pageflip-navigation
	> ul.hc-flipbook-nav,
div.hc-pageflip-navigation
	> ul.hc-flipbook-nav--white {
	position: absolute;
	margin: 0;
    top: 120px;
    left: 50px;
    display: block;
    min-width: 240px;
    .hc-btn {
		background-image: url('/img/holiday/arrow-grey.svg');
		&:hover, {
			background-image: url('/img/holiday/arrow.svg');
		}
    }
	li:before{
		content: '';
		width: 0;
		margin-left: 0;
		display: inline;
	}
	li{
		list-style:none;
		font-size:20px;
		padding-top:4px;
		padding-bottom:14px;
		line-height: 155%;
		margin:0;
		padding-left:0;
		a {
			display: block;
			color: #666;
			&:hover {
				color: #F48D35;
			}
		}
		ul.hc-flipbook-nav__sub{
			margin:5px 0 10px -5px;
			padding:0;

			li {
				padding: 0;
				//height: 38px;
				margin-bottom: 5px;
				a{
					font-size: 16px;
					color: #999;
					.toc__icon {
						transform: scale(.82);
						border: 1px solid $color-red;
						margin-right: 0;
						z-index: 2;
					}
					.hc-flipbook-nav__link-text,
					.hc-flipbook-nav__link-text--appendix {
						display: inline-block;
						height: 35px;
						line-height: 36px;
						padding: 0 25px 0 25px;
						margin-left: -20px;
						border-radius: 0 18px 18px 0;
					}
					&:hover {
						color: #F48D35;
					}
				}
			}

		}

		&.active {
			> a {
				color: #F48D35 !important;
				&.hc-btn {
					background-image: url('/img/holiday/arrow.svg');
				}
			}
		}

		&.appendix {
			padding-bottom: 15px;
			padding-top: 6px;
			ul.hc-flipbook-nav__sub{display:block;}
		}

		&.active.sub{
			padding-top: 15px;
			ul.hc-flipbook-nav__sub{display:block;}
		}
	}
}

.hc-btn {
	padding-right: 35px;
	background: url('/img/holiday/arrow.svg') no-repeat 100% 50%;
    //line-height: 24px;
    &--white {
    	color: white;
    	&:hover {
    		color: #F48D35;
    	}
    }
}

.hc-start-btn {
    position: absolute;
    font-size: 26px;
    top: 575px;
    left: 73px;
    color: #fff;
    background-size: 40px 40px;
    line-height: 40px;
    padding-right: 60px;
}

.page-content {
	.btn-social--tweet {
		font-size: 14px;
		border-radius: 30px;
		padding: 12px 40px 10px 15px;
		background-position: 88% 50%;
	}
	.hc-cta--subscribe {
		font-size: 18px;
		border-radius: 20px;
		padding: 6px 60px 4px 15px;
		background-image: url('/img/holiday/arrow-white.svg');
		background-repeat: no-repeat;
		background-position: 95% 50%;
	}
}

#bounce-back-btn{
	cursor:pointer;
	position:absolute;
	top: 540px;
    left: 910px;
}

#see-example-btn{
	cursor:pointer;
	position:absolute;
    top: 540px;
    left: 413px;
}

#contact-us{
	cursor:pointer;
	position:absolute;
	top: 550px;
    left: 465px;
}

#logos {
	position:absolute;
	top: 465px;
	left: 835px;
}


