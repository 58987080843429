// colors
$brandcolor: #F48D35;
$branddarkgrey: #54585A;
$brandmediumgrey: #A9A9A9;
$brandlightgrey: #A9A9A9;

// social colors
$brandfacebook: #3B5998;
$brandtwitter : #00ACED;
$brandgoogle  : #DD4B39;
$brandlinkedin: #007BB6;

// page colors
$color-purple: #642F6C;
$color-yellow: #FFC845;
$color-orange: #FF8F1C;
$color-red: #CE0037;

// transition speed
$fast: 0.2s;
$slow: 0.3s;

// fonts
$font: 'HurmeGeometricSans2-Regular', Arial, sans-serif;
$fontbold: 'HurmeGeometricSans2-SemiBold', Arial, sans-serif;

/* XSmall devices only (less 767) */
$screen-xs-max: 767px;

/* Small devices (tablets, 768px and up) */
$screen-sm-min: 768px;
//@media (min-width: $screen-sm-min) { ... }

/* Medium devices (desktops, 992px and up) */
$screen-md-min: 992px;
//@media (min-width: $screen-md-min) { ... }

/* Large devices (large desktops, 1200px and up) */
$screen-lg-min: 1200px;
//@media (min-width: $screen-lg-min) { ... }

