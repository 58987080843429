/* AddThisEvent theme CSS
---------------------------------------------------------------------- */

/* General */
.addeventstc 							{visibility:hidden;}
.addeventstc span 						{display:none!important;}
.addeventstc .data 						{display:none!important;}

/* The button */
.addeventstc-drop 						{display:inline-block;position:relative;z-index:999998;text-decoration:none;cursor:pointer;}
//.addeventstc-drop:hover 				{color:#555;font-size:16px;text-decoration:underline;}


/* The drop down */
.addeventstc_dropdown 					{width:200px;position:absolute;z-index:99999;padding:6px 0px 0px 0px;background:#fff;text-align:left;display:none;margin-top:4px;margin-left:15px;border-top:1px solid #c8c8c8;border-right:1px solid #bebebe;border-bottom:1px solid #a8a8a8;border-left:1px solid #bebebe;-moz-border-radius:2px;-webkit-border-radius:2px;-webkit-box-shadow:1px 3px 6px rgba(0,0,0,0.15);-moz-box-shadow:1px 3px 6px rgba(0,0,0,0.15);box-shadow:1px 3px 6px rgba(0,0,0,0.15);}
.addeventstc_dropdown span 				{display:block;line-height:100%;background:#fff;text-decoration:none;font-size:14px;color:#333;padding:9px 10px 9px 40px;}
.addeventstc_dropdown span:hover 		{background-color:#f4f4f4;color:#000;text-decoration:none;font-size:14px;}
.addeventstc_dropdown em 				{color:#999!important;font-size:12px!important;}
.addeventstc_dropdown .frs 				{background:#fff;color:#cacaca!important;cursor:pointer;font-size:9px!important;font-style:normal!important;font-weight:normal!important;line-height:110%!important;padding-left:10px;position:absolute;right:10px;text-align:right;text-decoration:none;top:5px;z-index:101;}
.addeventstc_dropdown .frs:hover 		{color:#999!important;}
.addeventstc_dropdown .ateappleical 	{background-image:url(http://addthisevent.com/gfx/dropdown-apple-t1.png);background-position:13px 50%;background-repeat:no-repeat;}
.addeventstc_dropdown .ateoutlook 		{background-image:url(http://addthisevent.com/gfx/dropdown-outlook-t1.png);background-position:13px 50%;background-repeat:no-repeat;}
.addeventstc_dropdown .ateoutlookcom 	{background-image:url(http://addthisevent.com/gfx/dropdown-outlook-t1.png);background-position:13px 50%;background-repeat:no-repeat;}
.addeventstc_dropdown .ategoogle 		{background-image:url(http://addthisevent.com/gfx/dropdown-google-t1.png);background-position:13px 50%;background-repeat:no-repeat;}
.addeventstc_dropdown .ateyahoo 		{background-image:url(http://addthisevent.com/gfx/dropdown-yahoo-t1.png);background-position:13px 50%;background-repeat:no-repeat;}
.addeventstc_dropdown .atefacebook 		{background-image:url(http://addthisevent.com/gfx/dropdown-facebook-t1.png);background-position:13px 50%;background-repeat:no-repeat;}
.addeventstc_dropdown .copyx 			{height:21px;display:block;position:relative;cursor:default;}
.addeventstc_dropdown .brx 				{height:1px;overflow:hidden;background:#e0e0e0;position:absolute;z-index:100;left:10px;right:10px;top:9px;}