

/*
Countdown Row

Markup:
<div class="row row-overflow hc-countdown">
	<div class="container">
		<div class="col-md-3 hc-countdown__column hc-countdown__column--1">
			<div class="hc-countdown__days" data-countdown="2015/09/01"></div>
			<span class="hc-countdown__sub">days until<br> quarter begins</span>
			<hr>
			<span class="hc-countdown__date">Sept 01, 2015</span>
		</div>
		<div class="col-md-3 hc-countdown__column hc-countdown__column--2">
			<div class="hc-countdown__days" data-countdown="2015/11/30"></div>
			<span class="hc-countdown__sub">days until<br> cyber monday</span>
			<hr>
			<span class="hc-countdown__date">Nov 30, 2015</span>
		</div>
		<div class="col-md-3 hc-countdown__column hc-countdown__column--3">
			<div class="hc-countdown__days" data-countdown="2015/12/15"></div>
			<span class="hc-countdown__sub">days until peak<br> season begins</span>
			<hr>
			<span class="hc-countdown__date">Dec 15, 2015</span>
		</div>
		<div class="col-md-3">
			<div class="hc-countdown__social">
				<h4>Share this page:</h4>
				<div class="social-colored">
					<a href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fsitepoint.com%2F" class="btn-social">Facebook</a>
					<a href="https://twitter.com/intent/tweet?url=http%3A%2F%2Fsitepoint.com%2F&text=Holiday%20e-Commerce%20Countdown" class="btn-social">Twitter</a>
					<a href="http://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fsitepoint.com%2F&title=Holiday%20e-Commerce%20Countdown" class="btn-social">LinkedIn</a>
				</div>
			</div>
			<h3>Want to stay up-to-date?</h3>
			<!-- Subscribe to Calendar button -->
			<div title="Subscribe to Calendar" class="addeventstc hc-cta hc-cta--subscribe" data-id="nj101">
				<span class="hc-cta__label">Subscribe to Calendar</span> <span class="glyphicon glyphicon-chevron-right"></span>
			</div>
		</div>
	</div>
</div>

Styleguide 1.2.

*/

.hc-countdown {
	color: $branddarkgrey;
	&__column {
		margin-bottom: 30px;
		&--1 {
			@include countdowncolumn($color-purple);
		}
		&--2 {
			@include countdowncolumn($color-yellow);
		}
		&--3 {
			@include countdowncolumn($color-orange);
		}
		&--4 {
			@include countdowncolumn($color-red);
		}
	}
	hr {
		margin: 5px 0 10px;
	}
	&__days {
		font-size: 50px;
		line-height: 56px;
	}
	&__sub {
		font-size: 16px;
		line-height: 20px;
		display: block;
		text-transform: lowercase;
	}
	&__sub-day {
		font-size: 22px;
		line-height: 26px;
		text-transform: lowercase;
	}
	&__date {
		text-transform: uppercase;
	}
	&__social {
		margin-bottom: 30px;
		h4 {
			margin-bottom: 10px;
		}
	}
	h3 {
		font-size: 22px;
		line-height: 26px;
	}
	@media (min-width: $screen-md-min) {
		padding: 50px 0 10px;
		&__column {
			margin-bottom: 50px;
		}
		&__days {
			font-size: 68px;
			line-height: 68px;
		}
		&__sub-day {
			min-height: 52px;
			display: inline-block;
		}
		&__subscribe-text {
			//font-size: 18px;
			//line-height: 22px;
		}
	}
	@media (min-width: $screen-lg-min) {
		&__sub {
			//font-size: 20px;
			//line-height: 24px;
		}
		&__sub-day {
			font-size: 26px;
			line-height: 32px;
			min-height: 64px;
		}
		h3 {
			font-size: 26px;
			line-height: 30px;
		}
	}
}



