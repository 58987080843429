

/*
Twitter Row

Markup:
<div class="row hc-twitter">
	<div class="container">
		<div class="col-md-12">
			<div class="hc-twitter__callout">23%</div>
			<blockquote>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</blockquote>
			<a href="https://twitter.com/intent/tweet?text=Lorem%20ipsum%20dolor%20sit%20amet%2C%20consectetur%20adipiscing%20elit%2C%20sed%20do%20eiusmod%20tempor%20incididunt%20ut%20labore%20et%20dolore%20magna%20aliqua." class="btn-social btn-social--tweet hc-cta hc-cta--white">Tweet this</a>
		</div>
	</div>
</div>

Styleguide 1.3.

*/

.hc-twitter {
	padding: 50px 0 65px;
	background: #ef8d3b;
	background: -moz-linear-gradient(left, #ef8d3b 0%, #f9c657 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%, #ef8d3b), color-stop(100%, #f9c657));
	background: -webkit-linear-gradient(left, #ef8d3b 0%, #f9c657 100%);
	background: -o-linear-gradient(left, #ef8d3b 0%, #f9c657 100%);
	background: -ms-linear-gradient(left, #ef8d3b 0%, #f9c657 100%);
	background: linear-gradient(to right, #ef8d3b 0%, #f9c657 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ef8d3b', endColorstr='#f9c657', GradientType=1);
	blockquote {
		background: none;
		font-size: 22px;
		line-height: 28px;
		color: white;
		padding: 0;
	}
	&__callout {
		position: absolute;
		left: 50%;
		bottom: 0;
		//z-index: -1;
		font-size: 150px;
		line-height: 75px;
		color: white;
		opacity: .2;
	}
	@media (min-width: $screen-sm-min) {
		blockquote {
			font-size: 36px;
			line-height: 46px;
		}
	}
	@media (min-width: $screen-md-min) {
		blockquote {
			padding: 0 15% 0 0;
		}
		&__callout {
			font-size: 250px;
			line-height: 150px;
		}
	}
	@media (min-width: $screen-lg-min) {
		blockquote {
			padding: 0 30% 0 0;
		}
	}
}


