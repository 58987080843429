body {
	margin: 0;
}
.pageflip-container {
	padding-top: 1px;
}
.page-container {
	background-color: white;
	background-repeat: no-repeat;
	background-size: cover !important;
	background-position: 50% 50%;
}
#pageflip-thumbnails.hidden {
	display: block !important;
	visibility: visible !important;
}

#b-pinterest,
#b-facebook,
#b-twitter,
#b-google,
#b-play {
	display: none;
}
#stage {
	background: #444444;
}
.left-side,
.right-side,
.thumbnail-spread,
.thumbnail-page {
	//background: #888;
}
#pageflip-controls,
#pagerin {
	color: rgba(255,255,255,0.5);
}
.control-bar-button g,
.control-bar-pager g {
	fill: rgba(255,255,255,0.5);
}
.control-bar-button:not(.disabled):hover g {
	fill: #FFAA00;
}
#pageflip-controls {
	background: rgba(68,68,68,0.35);
}
.hotspot {
	position: absolute;
	overflow: hidden;
	cursor: pointer;
}

.right-buttons {
	.hc-cta {
		margin-top: -7px;
		.glyphicon {
			top: -2px;
		}
	}
}


.svg-content {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}


