.row-overflow {
	overflow: visible;
}

.row-eq-height {
	display: flex;
}


.hc-cta {
	display: inline-block;
	background: $brandcolor;
	//font-family: $fontbold;
	color: white;
	border-radius: 40px;
	//height: 40px;
	//line-height: 43px;
	padding: 11px 30px 8px;
	.glyphicon {
		vertical-align: middle;
		top: 0;
		//line-height: 38px;
	}
	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
	}
	&__label {
		display: inline-block;
		vertical-align: middle;
		line-height: 1;
	}
	&--white {
		background: white;
		color: $branddarkgrey;
	}
	&--red {
		background: $color-red;
		//color: $branddarkgrey;
	}
	&--subscribe {
		padding-right: 50px;
		.glyphicon {
			position: absolute;
			top: 50%;
			right: 30px;
			transform: translateY(-50%);
		}
	}
}


.btn-social--tweet {
	padding-right: 60px;
	background: white url(icons/twitter.svg) no-repeat 80% 50%;
}




@mixin countdowncolumn($color) {
	.hc-countdown__days {
		color: $color;
	}
	hr {
		border-color: $color;
	}
	.hc-countdown__date {
		color: $color;
	}
}

