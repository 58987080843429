

/*
Header

Markup:
<div class="row hc-header">
	<!-- start embed pageflip -->
	<div id="pageflip" class="hc-header__pageflip">
	</div>
	<!-- end embed pageflip -->
	<div class="container">
		<div class="col-md-12">
			<h1>Holiday<br> e-Commerce<br> Countdown</h1>
			<p class="hc-header__tagline">Your 10-step checklist for performance 2015</p>
			<a href="/pdf/criteo-holiday-2015.pdf" class="hc-cta hc-cta--white js-read-report"><span class="hc-cta__label">Read the report</span> <span class="glyphicon glyphicon-chevron-right"></span></a>
		</div>
	</div>
</div>

Styleguide 1.1.

*/

.hc-header {
	background-color: #444;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	&__pageflip-close {
		opacity: 0;
		position: absolute;
		top: 30px;
		right: 30px;
		z-index: 2;
		font-size: 18px;
		font-family: $fontbold;
		transition: opacity .5s ease-in;
	}
	&--in {
		.hc-header__pageflip-close {
			opacity: 1;
		}
	}
	&__pageflip {
		//display: none !important;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		transition: transform .5s ease-in;
		transform: translateY(-100%);
		&--in {
			transform: translateY(0);
		}
	}
	h1 {
		color: white;
		font-size: 40px;
		line-height: 48px;
	}
	p {
		color: white;
	}
	&__tagline {
		font-size: 22px;
		line-height: 26px;
	}
	&__tagline,
	&__sub {
		width: 80%;
	}
	@media (max-width: $screen-xs-max) {
		padding: 40px 0;
	}
	@media (min-width: $screen-sm-min) {
		height: 400px;
		.container {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
		&__tagline,
		&__sub {
			width: 60%;
		}
		&__pageflip {
			//display: block !important;
		}
	}
	@media (min-width: $screen-md-min) {
		height: 560px;
		h1 {
			font-size: 64px;
			line-height: 72px;
		}
		&__tagline {
			font-size: 26px;
			line-height: 32px;
		}
		&__sub {
			font-size: 18px;
			line-height: 22px;
		}
	}
	@media (min-width: $screen-lg-min) {
		&__tagline {
		}
		&__sub {
			margin: 0;
		}
		.hc-cta--red {
			position: absolute;
			bottom: 5px;
			right: 0;
		}
	}
}

